import {
    defineStore,
} from 'pinia';
import settingsApi from "@/services/settings";

export const userStore = defineStore('UserStore', {
    // State
    state: () => {
      const store = {
        userId: null,
        orgId: null,
        name: null,
        firstName: null,
        lastName: null,
        org: null,
        email: null,
        phone: null,
        token: null,
        isAuth: false,
        workspaces: [],
        currentWorkspace: null,
        viewOnly: true,
        isAdmin: false,
        isSuperAdmin: false,
        currentConnectorId: null,
        config: {},
        auth: {},
        schemaName: null,
        connectionType: null,
        notifications:[],
        welcome: false,
        welcomeStep: 1,
        chatId: null,
        subscription: null,
        white_label_accent: '#ECEFF1',
        white_label_primary: '#004D40',
        white_label_colours: null,
        white_label_logo: null,
        white_label_font: null,
        white_label_enabled: false,
        embeds_enabled: false,
        currentPipelineNodes: [],
        aiEnabled: process.env.VUE_APP_AI_ENABLED === 'true'
      }
      return store
    },
    persist: {
      storage: window.localStorage,
    },
    // Actions
    actions: {
      login(userData){
        this.userId = userData.user.user_id;
        this.orgId = userData.user.org_id;
        this.firstName = userData.user.first_name;
        this.lastName =  userData.user.last_name;
        this.org =  userData.user.org;
        this.isAdmin = userData.user.is_admin;
        this.isSuperAdmin = userData.user.is_super_admin;
        this.viewOnly = userData.user.view_only;
        this.name = `${userData.user.first_name} ${userData.user.last_name}`;
        this.token = userData.token;
        this.email = userData.user.email;
        this.workspaces = userData.user.workspaces;
        const obj = userData.user.workspaces.find(o => o.name === 'My Workspace');
        this.currentWorkspace = obj.workspace_id
        this.subscription = userData.user.subscription_id
        this.isAuth = true;
        this.white_label_accent = userData.user.white_label_accent;
        this.white_label_primary = userData.user.white_label_primary;
        this.white_label_colours = userData.user.white_label_colors;
        this.white_label_logo = userData.user.white_label_logo;
        this.white_label_font = userData.user.white_label_font;
        this.white_label_enabled = userData.user.white_label_enabled;
        this.embeds_enabled = userData.user.embeds_enabled;
      },
      logout(){
        this.userId =  null;
        this.name = null;
        this.email = null;
        this.token = null;
        this.isAuth = false;
      },
      setWorkspace(id){
        this.currentWorkspace = id
      },
      setConnectionType(connectionType){
        this.connectionType = connectionType
      },
      setConfig(config){
        this.config = config
      },
      setAuth(auth){
        this.auth = auth
      },
      setSchemaName(schemaName) {
        this.schemaName = schemaName
      },
      addNotification(title, type){
        this.notifications.push({title, type})
      },
      removeNotification(index){
        this.notifications.splice(index, 1)
      },
      setWorkspaces(userId){
        settingsApi.getForUser(userId)
                .then(res => {
                    this.workspaces = res.data
                })
                .catch(err => {
                    console.error(err)
                })
        this.chatId = null
      },
      setProfile(firstName, lastName, email) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.name = `${firstName} ${lastName}`
      },
      increaseStep(){
        this.welcomeStep++
      },
      setChatId(id) {
        this.chatId = id
      },
      setCurrentPipelineNodes(nodes) {
        this.currentPipelineNodes = nodes
      }
    },
    // Getters
    getters: {
      getUserId: (state) => state.userId,
      getOrgId: (state) => state.orgId,
      isLoggedIn: (state) => state.isAuth,
      getToken: (state) => state.token,
      getName: (state) => state.name,
      getEmail: (state) => state.email,
      getOrg: (state) => state.org,
      getWorkspaces: (state) => state.workspaces,
      getCurrentWorkspace: (state) => state.currentWorkspace,
      getConnectionType: (state) => state.connectionType,
      getConfig: (state) => state.config,
      getAuth: (state) => state.auth,
      getSchemaName: (state) => state.schemaName,
      getViewOnly: (state) => state.viewOnly,
      getWelcomeState: (state) => state.welcome,
      getWelcomeStep: (state) => state.welcomeStep,
      getCurrentChatId: (state) => state.chatId,
      getSubscription: (state) => state.subscription,
      getWhiteLabel: (state) => state.white_label_enabled,
      getWhiteLabelLogo: (state) => state.white_label_logo,
      getWhiteLabelPrimary: (state) => state.white_label_primary,
      getWhiteLabelColours: (state) => state.white_label_colours,
      getWhiteLabelFont: (state) => state.white_label_font,
      getEmbedsEnabled: (state) => state.embeds_enabled,
      getCurrentPipelineNodes: (state) => state.currentPipelineNodes,
      getAiEnabled: (state) => state.aiEnabled
    }
  
  })
  