<template>
	<v-container class="pl-15">
		<v-row class="pt-7 pl-15 ml-5">
			<v-col cols="10">
				<v-combobox
					v-model="model"
					:filter="filter"
					:hide-no-data="!search"
					:items="items"
					:search-input.sync="search"
					label="Ask any question about your data"
					multiple
					small-chips
					solo
					light
					item-text="search_bar_name"
					item-value="search_bar_name"
					return-object
				>
					<template v-slot:no-data>
						<v-list-item>
							<span class="subheading">Create (Press enter to add)</span>
							<v-chip color="light-grey" label class="ml-1">
								{{ search }}
							</v-chip>
						</v-list-item>
					</template>
					<template v-slot:selection="{attrs, item, parent, selected}">
						<v-chip
							v-if="item === Object(item)"
							v-bind="attrs"
							:color="`${item.color}`"
							:input-value="selected"
							label
						>
							<span class="pr-2">
								{{ item.search_bar_name }}
							</span>
							<v-icon small @click="parent.selectItem(item)">
								$delete
							</v-icon>
						</v-chip>
					</template>
					<template v-slot:item="{item}">
						<v-chip :color="`${item.color}`" dark label>
							<v-icon small class="mr-2">{{
								item.icon === undefined
									? 'fa-regular fa-question'
									: item.icon
							}}</v-icon>
							<strong>{{ item.search_bar_name }}</strong>
						</v-chip>
					</template>
				</v-combobox>
			</v-col>
			<v-col cols="2">
				<v-btn large color="white" @click="askQuestion()">
					<v-icon color="primary">mdi-magnify</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="answerDialog" width="66%">
			<v-card height="750" color="accent">
				<v-progress-linear
					indeterminate
					color="primary"
					absolute
					top
					v-show="isLoading"
				></v-progress-linear>
				<v-card-title
					>{{ this.localMsg }}
					<v-spacer></v-spacer>

					<v-icon
						v-show="!isLoading"
						color="primary"
						@click="saveImg(ids[0])"
						class="mr-3"
						>mdi-download</v-icon
					>

					<v-icon v-show="!isLoading" text @click="answerDialog = false"
						>mdi-close</v-icon
					>
				</v-card-title>
				<v-card-subtitle
					>Answer is AI generated, sometimes it will be
					incorrect.</v-card-subtitle
				>
		
									<!--<v-progress-circular
										indeterminate
										color="primary"
									></v-progress-circular>-->
									<div style="display: flex; justify-content: center; align-items: center;" v-if="isLoading">
    <LogoLoader height="100" width="100"/>
</div>
<v-card-text class="text--overline mt-n5" style="text-align: center;">Generating Response...</v-card-text>
						
				<div v-for="id in ids" :key="id" height="650px">
					<canvas :id="id + '-chart'" width="100%" class="pa-3"></canvas>
				</div>
				<v-card-actions v-show="!isLoading" class="pb-3"> </v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import datasetsApi from '@/services/datasets';
import {userStore} from '@/stores/UserStore';
import chatApi from '@/services/chat';
import LogoLoader from '@/components/LogoLoader.vue';

export default {
	name: 'SearchBar',
	setup() {
		const user = userStore();
		return {
			user,
		};
	},
	components: {
		LogoLoader
		//HelpButton
	},
	data: () => ({
		activator: null,
		attach: null,
		colors: [
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
			'primary',
		],
		editing: null,
		editingIndex: -1,
		items: [
			{
				header:
					'Type your question, hit enter to add save parts and then select values from the list to insert them. E.g Total of X(Picked from list) by Y(Picked from list)',
			},
		],
		nonce: 1,
		menu: false,
		model: [],
		x: 0,
		search: null,
		y: 0,
		answerDialog: false,
		ids: [],
		chat: [],
		localMsg: null,
		isLoading: false
	}),
	computed: {
		workspaceId() {
			return this.user.getCurrentWorkspace;
		},
	},
	watch: {
		model(val, prev) {
			if (val.length === prev.length) return;

			this.model = val.map((v) => {
				if (typeof v === 'string') {
					v = {
						text: v,
						search_bar_name: v,
						color: this.colors[this.nonce - 1],
						type: 'userInput',
					};

					this.items.push(v);

					this.nonce++;
				}

				return v;
			});
		},
		id(newVal, oldVal) {
			if (oldVal) {
				const canvas = document.getElementById(`${oldVal}-chart`);
				if (canvas) {
					canvas.remove();
				}
			}
		},
		workspaceId(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.updateItems(newVal);
			}
		},
	},
	created() {
		if (this.user.getUserId) {
			this.updateItems(this.user.getCurrentWorkspace);
		}
	},
	methods: {
		edit(index, item) {
			if (!this.editing) {
				this.editing = item;
				this.editingIndex = index;
			} else {
				this.editing = null;
				this.editingIndex = -1;
			}
		},
		filter(item, queryText, itemText) {
			if (item.header) return false;

			const hasValue = (val) => (val != null ? val : '');

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return (
				text
					.toString()
					.toLowerCase()
					.indexOf(query.toString().toLowerCase()) > -1
			);
		},
		updateItems(workspaceId) {
			datasetsApi
				.getMappingWorkspace(workspaceId)
				.then((response) => {
					this.items = [{ header: 'Type your question, hit enter to add save parts and then select values from the list to insert them. E.g [Total of] [X (Picked from list)] [by] [Y (Picked from list)]', }];
					this.items = this.items.concat(response.data);
				})
				.catch((err) => {if (err) {console.log('error')}})
		},
		timeout(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		askQuestion() {
			this.ids = [];
			this.isLoading = true;
			this.answerDialog = true;

			this.localMsg = this.model
				.map((item) => item.search_bar_name)
				.join(' ');
			const selectedDatasets = this.model
				.map((item) => item.dataset_id)
				.filter((item) => item !== undefined);

			chatApi
				.chat(
					this.user.getUserId,
					this.localMsg,
					[...new Set(selectedDatasets)],
          'chart',
          this.user.getCurrentWorkspace,
          this.user.getCurrentChatId
				)
				.then(async (res) => {

if (res.data.javascriptCode !== null) {
						this.chat.push({
							role: 'assistant',
							content: 'Chart',
							actions: [],
							id: res.data.logId,
						});
            this.ids.push(res.data.logId);
            this.user.setChatId(res.data.chatId)
						await this.timeout(500);

						new Function(res.data.javascriptCode)();
						this.isLoading = false;
					} else {
						this.msg = null;
						this.chat.push({
							role: 'assistant',
							content: res.data.result,
							actions: [],
							id: res.data.logId,
            });
            this.user.setChatId(res.data.chatId)
						this.ids.push(res.data.logId);
					}
				})
				.catch(() => {
					this.chat.push({
						role: 'assistant',
						content:
							'Sorry, I did not understand that, please add more information to your question',
						actions: [],
					});
					this.isLoading = false;
				});
		},
		saveImg(id) {
			// Get the canvas
			const canvas = document.getElementById(`${id}-chart`);
			// Convert the canvas to data
			const image = canvas.toDataURL();
			// Create a link
			const aDownloadLink = document.createElement('a');
			// Add the name of the file to the link
			aDownloadLink.download = 'ivp.png';
			// Attach the data to the link
			aDownloadLink.href = image;
			// Get the code to click the download link
			aDownloadLink.click();
		},
	},
};
</script>
